// Body
$body-bg: #ffffff;

$fa-font-path: "../webfonts";

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Header
$header-height: 80px;
$header-height-mobile: 55px;

// Footer
$footer-height: 231px;
$footer-height-mobile: 90px;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$success: #CDF4DD;
$error: #FFD2D2;

$mainColor: #1FADA1;
$secondColor: #003150;
$black: #1B1C1E;
$greenMenu: #166F6A;